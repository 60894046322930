/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveContentBuilder from "@dataResolvers/resolveContentBuilder";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import PageTemplate from "@pageTemplates/ResourceSinglePage";
// eslint-disable-next-line no-unused-vars
import ContentBuilderFragment from "@fragments/ContentBuilderFragment";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ResourceSingleQuery($uri: [String], $site: [String]) {
    craft {
      entry(uri: $uri, site: $site) {
        type: typeHandle
        title
        uid
        url
        slug
        ... on Craft_resources_article_Entry {
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          contentBuilder {
            ...ContentBuilderFragment
          }
        }
        ... on Craft_resources_default_Entry {
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          pageBuilder {
            ...PageBuilderFragment
          }
        }
        ... on Craft_resources_firesideChat_Entry {
          video: heading0
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          contentBuilder {
            ...ContentBuilderFragment
          }
        }
        ... on Craft_resources_webinar_Entry {
          video: heading0
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          contentBuilder {
            ...ContentBuilderFragment
          }
        }
        ... on Craft_resources_whitepaper_Entry {
          # Meta
          authors {
            ... on Craft_people_default_Entry {
              title
            }
          }
          audiences {
            title
            uid
            slug
          }
          topics {
            title
            uid
            slug
          }
          date: postDate
          hideImage: boolean0
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          contentBuilder {
            ...ContentBuilderFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    video,
    type,
    audiences,
    topics,
    contentBuilder,
    pageBuilder,
    // Meta
    url,
    title,
    authors,
    date,
    hideImage,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
  } = entry;

  return {
    type,
    audiences,
    topics,
    hero: {
      heading: title,
      descriptor: metaDescription,
      video,
      image: resolveImage(metaImage),
      hideImage,
      authors,
      date,
    },
    blocks: contentBuilder
      ? resolveContentBuilder(contentBuilder)
      : resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  const eagerUrls = [];
  return <HeadTemplate {...rest} data={resolvedData} eagerUrls={eagerUrls} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ResourcesQuery"
    {...props}
  />
);

export default Page;
